import './App.css';

function App() {
  return (
    <div class="container">
        <h1>TheBigDataPy</h1>
        <p class="message">Launching on <b className='underline'>Jan 15, 2025!</b></p>
        <p class="follow">Follow me on <a href="https://www.linkedin.com/in/hsmgowtham/" rel="noreferrer" target="_blank">LinkedIn</a> to know the updates.</p>
    </div>
  );
}

export default App;
